<template>
    <main>
        <section class="bg-site-light" id="scroll-from-lead-form">
            <b-container>
                <b-row>
                    <b-col cols="12" md="12">
                        <h2 class="font-weight-bold text-center mb-1 mb-lg-4">
                            Выберите форму ведения бизнеса
                        </h2>
                        <div class="text-center mb-4 mb-lg-5 pb-lg-3">
                            Получите примерные формы документов для регистрации бизнеса
                            <a target="_blank" referrerpolicy="no-referrer" href="//egr.gov.by/egrn/"
                               class="color-site text-underline">
                                (подробная информация о регистрации бизнеса)
                            </a>
                        </div>
                    </b-col>
                </b-row>
                <b-card-group deck>
                    <tab-form v-for="tab in tabs"
                              :key="tab.id"
                              :tab="tab"
                    />
                </b-card-group>
<!--                <div class="text-size-sm text-center mt-4 mb-3 mb-md-4 pb-md-2">-->
<!--                    По вопросам корректности заполнения документов и консультации юриста в части регистрации бизнеса и-->
<!--                    осуществления предпринимательской деятельности, обращайтесь к нашему партнёру ООО "Эксперт Легал-->
<!--                    Сервис" msb.els24.by-->
<!--                </div>-->
                <u-button v-b-modal.call-modal :text="buttonText" class-button="d-flex mx-auto mt-5"/>
                <div v-if="show" class="form-pre">
                    <div class="h2 font-weight-bold text-center">
                        {{selectedTabFormName}}
                    </div>
                    <div class="text-center">{{ timestamp }}</div>
                </div>
                <basic-form :form="selectedForm" :key="selectedForm.id" v-if="show" id="scroll-from-tab-form"/>
            </b-container>
        </section>
        <section class="bg-white" id="scroll-from-main-list">
            <banner-with-form/>
        </section>
        <section class="bg-site-light">
            <h2 class="font-weight-bold text-center mb-md-4 pb-md-2">
                Преимущества открытия счета в Банке ВТБ:
            </h2>
            <u-img-text :rows="rows" class-box="col-md-6 images-box"/>
            <u-button
                    text="Открыть счет"
                    class-button="d-flex mx-auto long"
                    v-scroll-to="{el:'#scroll-from-main-list', offset: -25}"
            />
        </section>
        <section class="bg-white">
            <b-container>
                <b-row>
                    <b-col cols="12" md="5" order="1" order-md="0">
                        <u-img-blockquote col-classes="col-12 col-md-12"
                                          blockquote-classes="default pr-2 pr-lg-4"
                                          img-src="/img/main-banner-qr-2.png"
                                          img-alt="Воспользуйтесь сервисом онлайн регистрации бизнеса и получите доступ к новому продукту - Оплата товаров и услуг с помощью QR-кода."
                                          img-blockquote="Наведите камеру на QR код"
                        />
                    </b-col>
                    <b-col cols="12" md="7" order="0" order-md="1">
                        <h2 class="font-weight-bold mt-md-4 pt-md-1 mb-md-4 pb-md-2">
                            Воспользуйтесь сервисом онлайн регистрации бизнеса и получите доступ к новому продукту -
                            Оплата товаров и услуг с помощью QR-кода.
                        </h2>
                        <u-ul-list :list="list" variant="circle"/>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </main>
</template>

<script>
    import TabFormClass from "../../classes/tabForm";
    import {BusinessFormTabs} from "../../BusinessForms";
    import {ImgText} from "../../classes/imgText";
    // import Form from "../Header/Form";

    export default {
        name: "index",
        components: {
            // Form,
            TabForm: () => import(/* webpackChunkName: "tab-form-in-main" */'./TabForm'),
            BasicForm: () => import(/* webpackChunkName: "basic-form-in-main"*/'../BasicForm/'),
            BannerWithForm: () => import(/* webpackChunkName: "banner-with-form-in-main"*/ './BannerWithForm'),
            UImgText: () => import(/* webpackChunkName:"u-img-text"*/'../UImgText'),
            UButton: () => import(/* webpackChunkName: "u-button" */'../UButton'),
            UImgBlockquote: () => import(/* webpackChunkName: 'u-img-blockquote'*/'../UImgBlockquote'),
            UUlList: () => import(/* webpackChunkName: 'u-ul-list'*/'../UUlList'),
        },
        data() {
            return {
                /**
                 * Инициалилизует новый таб формы
                 *
                 * @return selectedTab
                 */
                selectedTab: new TabFormClass,
                selectedTabFormName: '',
                list: [
                    'Выгодно. Комиссия за прием платежей составляет всего 1,2% от суммы.',
                    'Быстро. Подарив покупателю оплату в один клик, вы мгновенно получаете информацию об оплате.',
                ],
                show: false,
                timestamp: '',
                formKey: 0,

                testInputsData: []
            }
        },
        created() {
            setInterval(this.updateTime, 1000);
        },
        computed: {
            /**
             * Вычисляет и возвращает выбранную форму
             *
             * @return form
             */
            selectedForm() {
                return this.selectedTab.form;
            },
            buttonText() {
                return '' +
                    '<i class="icon-call my-auto mr-2 mr-md-3"></i>' +
                    '<span class="my-auto">Нужна помощь? Мы перезвоним!</span>' +
                    ''
            },
            /**
             * Возвращает массив табов и форм
             *
             * @return BusinessFormTabs
             */
            tabs() {
                return BusinessFormTabs;
            },
            rows() {
                const rows = [];

                rows.push(new ImgText(
                    'Подключайте действительно выгодные ' +
                    '<a class="color-site text-underline" target="_blank" referrerpolicy="no-referrer" ' +
                    'href="//www.vtb-bank.by/malomu-biznesu/raschetno-kassovoe-obsluzhivanie/pakety-uslug">' +
                    'пакеты услуг</a>.',
                    '/img/icon/box.svg')
                );
                rows.push(new ImgText(
                    'Пополняйте счёт через ' +
                    '<a class="color-site text-underline" target="_blank" referrerpolicy="no-referrer"' +
                    'href="//www.vtb-bank.by/malomu-biznesu/raschetno-kassovoe-obsluzhivanie/priem-nalichnyh-cherez-ustroystva-samoobsluzhivaniya">' +
                    'устройства самообслуживания</a>' +
                    ' - без посещения офиса, с чековым подтверждением.',
                    '/img/icon/atm.svg')
                );
                rows.push(new ImgText(
                    'Управляйте своим бизнесом из любой точки мира через удобное мобильное приложение ' +
                    '<a class="color-site text-underline" target="_blank" referrerpolicy="no-referrer"' +
                    'href="//www.vtb-bank.by/malomu-biznesu/elektronnyy-bank/mobilnoe-prilozhenie-vtb-business">' +
                    '"VTB Business"</a>.',
                    '/img/icon/delivery-box.svg')
                );
                rows.push(new ImgText(
                    'Принимайте оплату товаров и услуг по ' +
                    '<a class="color-site text-underline" target="_blank" referrerpolicy="no-referrer"' +
                    'href="//www.vtb-bank.by/epos">QR-коду</a> ' +
                    'без карт и терминалов.',
                    '/img/icon/qr-code.svg')
                );
                rows.push(new ImgText(
                    'Безопасно совершайте платежи, моментально создавайте платежные документы, проверяйте доступные остатки на счетах в реальном режиме времени через удобный ' +
                    '<a class="color-site text-underline" target="_blank" referrerpolicy="no-referrer"' +
                    'href="//www.vtb-bank.by/malomu-biznesu/elektronnyy-bank/sistema-dbo-internet-bank-dlya-biznesa">' +
                    '«Интернет-банк»</a>.',
                    '/img/icon/credit-card.svg')
                );
                rows.push(new ImgText(
                    'Забудьте о проблемах с правом с сервисом «Личный юрист для бизнеса»',
                    '/img/icon/invoice.svg')
                );
                rows.push(new ImgText(
                    'Осуществляйте оплату в BYN 24/7 без выходных и праздничных дней с ' +
                    '<a class="color-site text-underline" target="_blank" referrerpolicy="no-referrer"' +
                    'href="//www.vtb-bank.by/malomu-biznesu/sistema-mgnovennyh-platezhey">' +
                    'системой мгновенных платежей.</a>',
                    '/img/icon/online-payment.svg')
                );
                rows.push(new ImgText(
                    'Откройте счет в надежном банке, который входит в международную финансовую ' +
                    '<a class="color-site text-underline" target="_blank" referrerpolicy="no-referrer" ' +
                    'href="//www.vtb.ru/o-banke/gruppa-vtb/">' +
                    'группу ВТБ</a>.<br>' +
                    'Банк ВТБ (Беларусь) работает на рынке Республики Беларусь с 1996 года и стабильно входит в десятку крупнейших банков страны.' +
                    'системой мгновенных платежей.</a>',
                    '/img/icon/accounting.svg')
                );

                return rows;
            }
        },
        methods: {
            tabWasSelected(tab) {
                // console.log(tab);

                if (tab instanceof TabFormClass) {
                    this.selectedTab = tab;
                    this.selectedTabFormName = this.selectedTab.form.name;
                    this.show = true;
                } else if (tab !== null) {
                    console.warn(tab);
                    throw new Error('tab is not instanceof TabForm');
                }
            },
            updateTime() {
                const cd = new Date();
                this.timestamp = this.zeroPadding(cd.getDate(), 2) + '.'
                    + this.zeroPadding(cd.getMonth() + 1, 2) + '.'
                    + this.zeroPadding(cd.getFullYear(), 4);
            },
            zeroPadding(num, digit) {
                let zero = '';
                for (let i = 0; i < digit; i++) {
                    zero += '0';
                }
                return (zero + num).slice(-digit);
            }
        },
        mounted() {
            this.$root.$on('tab-was-selected', this.tabWasSelected);
        }
    }
</script>
<style lang="scss">
    .images-box {
        img {
            height: 48px;
        }
    }
</style>
